export default {
  row: {
    display: [`block`, null, `flex`],
    flexWrap: `wrap`,
    mb: 3
  },
  column: {
    flex: `auto`,
    mb: [3, null, 0],
    '+ div': {
      ml: [0, null, 3]
    }
  },
  input: {
    fontFamily: `body`,
    border: `none`,
    bg: `contactFormInput`,
    color: `omegaDark`,
    outline: `none`,
    '::placeholder': {
      color: `omegaDark`
    }
  },
  textarea: {
    variant: 'forms.input',
    fontSize: 3,
    minHeight: 100
  },
  label: {
    mb: 2
  },
  form: {
    bg: `contactFormBg`
  },
  title: {
    textAlign: `center`,
    marginBottom: `10px`,
    color: `text`,
    fontSize: `22px`
  },
  title_1: {
    color: `text`,
    fontWeight: 700,
    marginBottom: `.5em`,
    marginTop: 0,
    fontSize: `1.25em`
  },
  subtitle: {
    color: `text`,
    textDecoration: `none`,
    fontsize: `1em !important`,
    margin: 0,
  }
}
