import React, { Fragment, useRef } from 'react';
import { Link as GLink } from 'gatsby';
import { GatsbyImage as Img } from 'gatsby-plugin-image';
import { Container, Box, Flex } from 'theme-ui';
import ContentText from '@solid-ui-components/ContentText';
import ContentButtons from '@solid-ui-components/ContentButtons';
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent';
import getImage from '@solid-ui-components/utils/getImage';
import { useColorMode } from 'theme-ui';
import SVG from 'react-inlinesvg';
import ConvertTextToComponent from '../../../../../content/components/ConvertTextToComponent';
import FullWidthPlaceHolder from '../../../../../content/components/shared/FullWidthPlaceHolder';
import { useHasBeenVisible } from '../../../../../hooks/useVisibility';
import useHeight from '../../../../../hooks/useHeight';

const styles = {
  wrapper: {
    position: `relative`,
    bg: `footerBg`,
  },
  appLink: {
    height: 50,
  },
  footer: {
    flexDirection: [`column-reverse`, `row`],
    justifyContent: `space-between`,
    alignItems: [`center`, `flex-start`],
    py: 4,
    pb: 0,
    a: {
      textDecoration: 'none !important',
    },
  },
  bottomWrapper: {
    justifyContent: 'space-between',
    alignItems: 'center',
    py: 3,
  },
  footerDescription: {
    flex: [1, 2],
    pr: '2',
    '@media(max-width: 640px)': {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      alignItems: 'center',
    },
  },
  heading: { display: `block`, fontSize: [`16px !important`, `18px`], mb: 3 },
  footerElement: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: [`center`, `flex-start`],
    textAlign: [`center`, `left`],
  },
  wrapperStyles: {
    fontSize: ['14px', '16px'],
    flexDirection: `column`,
    a: { marginLeft: ['-1rem', '0'], fontWeight: 400 },
  },
  trustPilot: {
    flexDirection: `row-reverse`,
    '@media(max-width: 640px)': {
      justifyContent: `center`,
      marginTop: `30px`,
    },
  },
};

const FooterBlock01 = ({
  content: { images, buttons, collection, text_1, text_2 },
  amp,
  customStyles,
  forceDark,
  showTrustPilot,
}) => {
  const trustPilotRef = useRef();
  const hasScrolledToTrustPilot = useHasBeenVisible(trustPilotRef);
  const [colorMode] = useColorMode();
  const isDark = colorMode === `dark`;
  const normalImage = getImage(images?.[0]?.src);
  const normalImageAMP = images?.[0]?.src.ImageSharp_ImageFluid;
  const darkImage = getImage(images?.[1]?.src);
  const darkCondition = isDark && forceDark;
  const cookieHeight = useHeight({ selector: '.CookieConsent', timeout: 7000 });
  const signUpHeight = useHeight({ selector: '#blog-signup-button' });
  return (
    <Box sx={{ ...styles.wrapper, ...customStyles }}>
      <Container px='4'>
        <Flex sx={styles.footer}>
          <Box sx={styles.footerDescription}>
            <Box pb='1' mb='2' mt={[4, 0]} sx={{ maxWidth: '250px' }}>
              <GLink to='/' style={{ position: 'relative' }}>
                {amp ? (
                  <amp-img
                    layout='responsive'
                    src={normalImageAMP.src}
                    srcset={normalImageAMP.srcSet}
                    width={250}
                    height={40}
                    alt={images?.[0]?.alt}
                    style={{
                      width: 250,
                      height: 40,
                      marginLeft: 0,
                      marginRight: 0,
                    }}
                  />
                ) : (
                  <Fragment>
                    <Img
                      image={darkImage}
                      alt={images?.[1]?.alt}
                      style={!darkCondition && { display: 'none' }}
                    />
                    <Img
                      image={normalImage}
                      alt={images?.[0]?.alt}
                      style={darkCondition && { display: 'none' }}
                    />
                  </Fragment>
                )}
                {/** to rendering conditionally throws error if user switch to dark mode. This method is more optimized! */}
              </GLink>
            </Box>
            <Box pt='2' mb={[2, 4]} pr='3'>
              {text_1?.text}
            </Box>
            <Box>
              <ConvertTextToComponent content={text_2.detailedTexts} />
              <a href={text_2.link}>{text_2?.text}</a>
            </Box>
          </Box>
          {collection?.map(
            ({ title, buttons }, index) =>
              buttons && (
                <Box key={`item-${index}`} sx={styles.footerElement} mb='4'>
                  <ContentText
                    content={title}
                    variant='h6'
                    sx={styles.heading}
                  />
                  <ContentButtons
                    content={[
                      ...buttons.map((button) => ({
                        ...button,
                        noprefetch: true,
                      })),
                    ]}
                    variant='vertical'
                    wrapperStyles={styles.wrapperStyles}
                    space={0}
                  />
                </Box>
              )
          )}
        </Flex>
      </Container>
      <Container pb={amp ? 230 : cookieHeight + signUpHeight}>
        {hasScrolledToTrustPilot ? (
          <Flex sx={styles.bottomWrapper}>
            <Box>
              {buttons.map((current) => (
                <GLink
                  to={
                    current.link +
                    '&referrer=' +
                    encodeURIComponent(window.location.href)
                  }
                  {...(current.target
                    ?
                    {
                      target: current.target === 'BLANK' && '_blank',
                      ...(current.target === 'BLANK' ? { rel: 'noopener noreferrer' }: {})
                    }
                    : '')}
                >
                  <SVG
                    src={current.icon.src}
                    alt='Google Play'
                    css={styles.appLink}
                  />
                </GLink>
              ))}
            </Box>
          </Flex>
        ) : (
          <FullWidthPlaceHolder
            reference={trustPilotRef}
            customStyle={{ height: 100 }}
          />
        )}
      </Container>
    </Box>
  );
};

FooterBlock01.defaultProps = {
  menuJustify: `flex-end`,
};

export default WithDefaultContent(FooterBlock01);
