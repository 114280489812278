export const languageOptions = [
  {
    title: 'EN (GB)',
    link: '/uk/',
  },
  {
    title: 'EN (US)',
    link: '/us/',
  },
  {
    title: 'IT (IT)',
    link: '/it/',
  },
  {
    title: 'ES (ES)',
    link: '/es/',
  },
  {
    title: 'DE (AT)',
    link: '/at/new-landing',
  },
  {
    title: 'DE (DE)',
    link: '/de/new-landing/',
  },
  {
    title: 'FR (BE)',
    link: '/be/new-landing',
  },
  {
    title: 'FR (FR)',
    link: '/fr/new-landing',
  },
  {
    title: 'EN (IE)',
    link: '/ie/new-landing/',
  },
  {
    title: 'BG (BG)',
    link: '/bg/new-landing/',
  },
  {
    title: 'GR (GR)',
    link: '/gr/new-landing/',
  },
  {
    title: 'Fi (Fi)',
    link: '/fi/new-landing/',
  },
  {
    title: 'CZ (CZ)',
    link: '/cz/new-landing/',
  },
  {
    title: 'DA (DA)',
    link: '/da/new-landing/',
  },
  {
    title: 'NL (NL)',
    link: '/nl/new-landing/',
  },
  {
    title: 'PL (PL)',
    link: '/pl/new-landing/',
  },
  {
    title: 'PT (PT)',
    link: '/pt/new-landing/',
  },
  {
    title: 'RO (RO)',
    link: '/ro/new-landing/',
  },
  {
    title: 'SK (SK)',
    link: '/sk/new-landing/',
  },
  {
    title: 'SI (SI)',
    link: '/si/new-landing/',
  },
  {
    title: 'SV (SV)',
    link: '/sv/new-landing/',
  },
  {
    title: 'HU (HU)',
    link: '/hu/new-landing/',
  },
];

export const allLanguages = languageOptions.map(
  ({ link }) => link.split('/').filter(Boolean)[0]
);
