import React, { Fragment } from "react";
import { Box } from "theme-ui";
import { nanoid } from "nanoid";
import styled from "@emotion/styled";
import { NavigationItem } from "@components/Navigation";

const Table = styled.table`
  width: 100%;
  white-space: pre-line;
  thead {
    font-weight: bold;
    color: #323233;
  }
  tbody {
    color: #666;
  }
  tr {
    font-size: 0.9em;
  }
  td {
    padding: 0.5em;
    border-bottom: 1px solid #ececec;
    a {
      color: black !important;
    }
    &:last-child {
      padding-right: 0;
    }
  }
`;

const TextWrapper = styled.div`
  // color: #404040;
  // h1,h2,h3,h4,h5,h6{
  //   color #000;
  // }
  strong {
    color: #323233;
  }
`;
export default function ConvertTextToComponent({ content, marginX, marginY }) {
  return <TextWrapper>{processText(content, marginX, marginY)}</TextWrapper>;
}
const processText = (content, marginX, marginY) => {
  return (
    <div>
      {content?.map(({ link, type, value, child, id }) => {
        return (
          <Box
            id={id}
            key={nanoid()}
            px={marginX || 0}
            py={marginY || 0}
            sx={{ a: { textDecoration: "underline !important" } }}
          >
            {["table", "dot", "number"].includes(type) ? (
              type === "table" ? (
                renderTable({ value, child })
              ) : (
                renderList({ child, type })
              )
            ) : (
              <Fragment>
                {renderText({ value, type })}
                {child && processText(child, marginX, marginY)}
              </Fragment>
            )}
          </Box>
        );
      })}
    </div>
  );
};
const renderList = ({ child, type }) => {
  const newKey = nanoid();
  const renderElements = () => {
    return child?.map(({ value }, index) => (
      <li key={`${newKey}-li-${index}`}>
         • {renderText({ value, type: "text" })}
      </li>
    ));
  };
  return type === "dot" ? (
    <ul key={`${newKey}-ul`}>{renderElements()}</ul>
  ) : (
    <ol key={`${newKey}-ol`}>{renderElements()}</ol>
  );
};
const renderTable = ({ value, child }) => {
  const newKey = nanoid();
  return (
    <>
      <Table key={`${newKey}-table`}>
        <thead key={`${newKey}-thead`}>
          <tr key={`${newKey}-thead-tr`}>
            {value.map((item) => (
              <td>{item}</td>
            ))}
          </tr>
        </thead>
        <tbody key={`${newKey}-tbody`}>
          {child.map((item, index) => (
            <tr key={`${newKey}-tr-${index}`}>
              {item.child.map(({ type, value }) => {
                const rowspanValue = type?.split("-")?.[1] || 1;
                const currentKey = nanoid();
                return (
                  <td rowSpan={rowspanValue} key={`${currentKey}-td`}>
                    <span key={`${currentKey}-span`}>
                      {renderText({ value, type: "text" })}
                    </span>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
const renderText = ({ value, type }) => {
  return value?.map((item) => {
    const newValue = decodeText(item);
    const htmlText = newValue && convertToText(newValue);
    return wrapText({ type, htmlText });
  });
};
// this functions convert "type" to html tags
const wrapText = ({ type, htmlText }) => {
  const newKey = nanoid();
  switch (type) {
    case "h1":
      return <h1 key={newKey}>{htmlText}</h1>;
    case "h2":
      return <h2 key={newKey}>{htmlText}</h2>;
    case "h3":
      return <h3 key={newKey}>{htmlText}</h3>;
    case "h4":
      return <h4 key={newKey}>{htmlText}</h4>;
    case "h5":
      return <h5 key={newKey}>{htmlText}</h5>;
    case "h6":
      return <h6 key={newKey}>{htmlText}</h6>;
    case "dot":
    case "number":
      return <li key={newKey}>{htmlText}</li>;
    case "bold":
      return <strong key={newKey}>{htmlText}</strong>;
    case "table":
      return <table key={newKey}>{htmlText}</table>;
    default:
      return htmlText;
  }
};

// this function converts inner styles to an object
// strong|this part will be bold
// link(www.togetherprice.com)|this part will be a link with href="http://www.togetherprice.com"
// more choice can be added
const decodeText = (text: string) => {
  const index = text.indexOf("|");
  if (index !== -1) {
    const values = text.split("|");
    if (values[0].substring(0, 4) === "link") {
      const link = values[0].substring(
        (values[0]?.indexOf("(") || 0) + 1,
        values[0].indexOf(")") || values[0].length
      );
      return { type: "link", href: link, value: values[1] };
    } else if (values[0].substring(0, 6) === "strong") {
      return { type: "strong", value: values[1] };
    } else if (values[0].substring(0, 4) === "line") {
      return { type: "line", value: values[1] };
    }
  } else {
    return { type: "text", value: text };
  }
};

// this function converts objects to html tags
const convertToText = ({
  type,
  value,
  href,
}: {
  type: string;
  value: string;
  href?: string;
}) => {
  const newKey = nanoid();
  switch (type) {
    case "text":
      return <span key={newKey}>{value} </span>;
    case "link":
      const isInternal = !href.includes("://") && href.startsWith("/");

      return (
        <NavigationItem
          name={value}
          {...{ [isInternal ? "slug" : "url"]: href }}
          sx={{ color: "alpha" }}
        />
      );
    case "line":
      return (
        <>
          <br key={`${newKey}-br`} style={{ marginBottom: "1rem" }} />
          <span key={`${newKey}-span`}>{value} </span>
        </>
      );
    case "strong":
      return <strong key={newKey}>{value} </strong>;
  }
};
