import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { RefObject } from 'react';

const Wrapper = styled.div`
  width: 100%;
  height: 800px;
  background-color: transparent;
`;
type Props = {
  reference?: RefObject<any>;
  customStyle?: any;
};
function FullWidthPlaceHolder({ reference, customStyle }: Props) {
  return <Wrapper css={css(customStyle)} ref={reference} />;
}

export default FullWidthPlaceHolder;
