import { useState, useEffect, useLayoutEffect } from 'react';
type Props = {
  selector: string;
  timeout?: number;
};

export default function useHeight({ selector, timeout = 5000 }: Props) {
  const [height, setHeight] = useState(0);
  const asyncQuerySelector = (selector) =>
    new Promise((resolve) => {
      const getElement = () => {
        const element = document.querySelector(selector);
        if (element) {
          resolve(element);
        } else {
          requestAnimationFrame(getElement);
        }
      };
      getElement();
      setTimeout(() => {
        resolve(null);
      }, timeout);
    });
  const cookieButtonClickListener = () => {
    // if element disappeared
    const element = document.querySelector(selector);
    if (!element) {
      setHeight(0);
    }
  };
  useEffect(() => {
    // if element renders first time
    asyncQuerySelector(selector).then((element?: HTMLDivElement) => {
      if (element) {
        element.addEventListener('click', cookieButtonClickListener);
        setHeight((_) => {
          const newHeight = element.clientHeight || 0;
          return newHeight;
        });
      }
    });
  });
  useEffect(() => {
    // if window resized
    function handleResize() {
      const element = document.querySelector(selector);
      setHeight((_) => {
        const newHeight = element?.clientHeight || 0;
        return newHeight;
      });
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
  return height;
}
